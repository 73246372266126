.page-title {
  text-align: center;
}

.avatar-container {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
}

.avatar-container .avatar {
  height: 40vh;
  border-radius: 20px;
}

.statement {
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

@media only screen and (max-width: 1000px) {
  .avatar {
    max-width: 3rem;
    max-height: 3rem;
  }
  .statement {
    margin: 0;
  }
}