* {
  box-sizing: border-box;
  font-family: sans-serif;
  background: #2e3539;
  color: #15e231;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: auto;
}

h1,
h3 {
  text-align: center;
}

h3 {
  color: black;
  padding-top: 1rem;
}

.description {
  margin-left: 30vw;
  margin-right: 30vw;
}

.project-img {
  border-radius: 15px!important; 
  max-width: 60vw;
  max-height: 60vh;

}

.project-img:hover {
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
  margin-bottom: 3rem;
}

.content {
margin-top: 3rem;
}

.btn-back {
  background-image: URL("../../../assets/images/back-button-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 6vw;
  height: 8vh;
  margin-left: 5vw;
  border-radius: 15px !important;
}

.btn-back:hover {
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
}

.github-img {
  height: 10vh;
  margin-left: 3vw;
}

.github-img:hover {
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
}