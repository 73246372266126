.contact-page-container {
  display: flex;
}

.contact-title {
  text-align: center;
}

.contact {
  padding-top: 2rem;
  padding-left: 10rem;
  padding-right: 4rem;
}

.form-container {
  margin-left: 0;
}

.resume {
  margin-top: 9rem;
  background-size: cover;
  width: 30rem;
  height: 26rem;
  margin-left: 5rem;
}

.form {
  margin: 1rem;
  margin-left: 10rem;

}

.hello {
  padding-top: 6rem;
  margin-left: 10rem;
}

.form input {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 30rem;
}

.message {
  width: 30rem;
  height: 30vh;
}

textarea.message {
  overflow-wrap: normal !important;
}

.submit:hover {
  color: black;
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.25);
}

@media only screen and (max-width: 1000px) {

  .form,
  .form input,
  .message {
    width: 80%;
    margin: 2rem;
  }

  .resume {
    display: none;
  }

}

@media only screen and (max-width: 600px) {

  .form,
  .form input,
  .message {
    width: 95%;
    margin: 0;
  }

}