.selectCard {
  background: #2e3539 !important;
}

.card {
  background: #2e3539;
  border-radius: 25px;
  min-height: 30vh;
  max-height: 60vh;
  margin: 1rem;
  min-width: 25vw;
  text-align: left;
}

img {
  margin-top: 1rem;
  border-radius: 10px;
}

.card:hover {
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.1);
}

.card > .img-container {
  height: 60%;
  overflow: hidden;
  text-align: center;
  background:#2e3539;
  background: cover;
}
.card > .img-container > img {
  width: 100%;
}

.card > .content {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 15px;
  color:#15e231;
}

.card > .content > ul {
  list-style-type: none;
}

.card > .content > ul > li {
  margin-top: 18px;
}

.selectCard .content {
  margin: 0;
  padding: 0;
}

.cardProject {
  font-size: large;
  padding-right: 3rem; 
  margin-right: 5px;
  margin-left: 0;
}

.cardDescription {
  font-size: large;
  padding-right: 1rem; 
}

.cardRepo {
  font-size: large;
  padding-right: 1rem; 
}

.cardDeploy {
  font-size: large;
  padding-right: 1rem; 
}

.content > ul > li {
  display: flex;
}

.content a {
  margin-left: 0;
  padding: 0;
}

.content .repoLink {
  padding-left: 1rem; 
}

.content a:hover {
  color: black;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.25);
}



