.about h3,
.skills h3 {
    text-align: left;
    ;
    margin-bottom: 2rem;
}

.about-content .content {
    margin-left: 8rem;
    margin-right: 8rem;
}

.content .skills {
    margin-top: 1rem;
}

.content .about {
    margin-bottom: 4rem;
}