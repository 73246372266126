
* {
  box-sizing: border-box;
  font-family: sans-serif;
  background: #2e3539;
  color:#15e231;
}

html, body, #root {
  padding: 0;
  margin: 0;
  height: auto;
  scroll-behavior: smooth;
}
