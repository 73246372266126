body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading {
color: rgb(14, 14, 16) !important;
font-weight: bold;
}

.nav a {
  color:chartreuse;
}

.header-link:hover {
  color:black;
  transition: transform ease-in-out 0.25s;
  transform: scale(1.25);
}
