
.tabtn:hover {
  background: black !important;
  color: white !important;
  box-shadow: 0 6px 40px #15e231, 0 6px 15px #999;
  transition: transform ease-in-out 0.2s;
  transform: scale(1.15);
}

.tabtn {
  background: #189c03 !important;
  color: #130b0b !important;
  width: 10rem;
  text-align: center;
}

.tabrow .tabtn .active {
  background: #130b0b;
}

hr {
  padding: 1rem;
}